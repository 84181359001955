import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/auth";
import helpers from "@/store/helpers";
import individual_entity from "@/store/individual_entity";
import project from "@/store/project";
import ticket from "@/store/ticket";
import cloud_host from "@/store/cloud_host";
import user from "@/store/user";


const ls = new SecureLS({isCompression: false});

Vue.use(Vuex)




export default new Vuex.Store({
    modules: {
        auth,
        helpers,
        project,
        ticket,
        cloud_host,
        user,
        individual_entity,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


